// extracted by mini-css-extract-plugin
export var ecombody = "ecommerce-module--ecombody--2V7MD";
export var formupper = "ecommerce-module--formupper--27WrR";
export var form = "ecommerce-module--form--2lQPr";
export var formitem = "ecommerce-module--formitem--1uOmv";
export var ecommvp = "ecommerce-module--ecommvp--3CkC6";
export var inner = "ecommerce-module--inner--1QUjN";
export var orderedupper = "ecommerce-module--orderedupper--3gCf0";
export var ordereditems = "ecommerce-module--ordereditems--QGnkX";
export var ordereditem = "ecommerce-module--ordereditem--2v4e9";
export var total = "ecommerce-module--total--hf1_Z";
export var warning = "ecommerce-module--warning--2MySo";
export var submitecom = "ecommerce-module--submitecom--2Salv";
export var featureselected = "ecommerce-module--featureselected--1lwPJ";
export var featureupper = "ecommerce-module--featureupper--ZX9k7";
export var checkout = "ecommerce-module--checkout--fE85d";
export var mfeatures = "ecommerce-module--mfeatures--d2KPT";
export var mtotal = "ecommerce-module--mtotal--3Bhgw";
export var dfeatureupper = "ecommerce-module--dfeatureupper--2jeWL";
export var former = "ecommerce-module--former--1DZq3";
export var number = "ecommerce-module--number--18yj-";
export var nothing = "ecommerce-module--nothing--1mGeo";
export var carouselcontainer = "ecommerce-module--carouselcontainer--3vdeZ";